import { createAction } from "@reduxjs/toolkit";
import { createReduxReducer } from "utilities";
import { resetStore } from "./auth";

// TYPES //

export type AvailableViewKeys =
  | "callCenterRoutes"
  | "callCenterRouteOrders"
  | "manufacturingRoute"
  | "materials"
  | "manufacturingIndexes"
  | "warehouseSchema"
  | "materialsDemandAndAvailability"
  | "callCenterOrders"
  | "financesSoldPackages"
  | "manufacturer"
  | "manufacturingOrderGroup"
  | "financesSoldIndexes"
  | "warehousePackagesInSummary"
  | "warehousePackagesOutSummary"
  | "shippingShipments"
  | "freeSingleItemsQuantitiesDetails"
  | "wmsCargoUnloads"
  | "shippingCourier"
  | "logisticsRouteTradingDocuments"
  | "logisticsRoutes"
  | "warehouseSchema"
  | "logisticsCarriers"
  | "logisticsCarriersOrders"
  | "logisticsRouteLineItems"
  | "orders"
  | "products"
  | "routes"
  | "upholsteryOrders"
  | "offers"
  | "profiles"
  | "complaints"
  | "productSets"
  | "customers"
  | "attributes"
  | "orderGroups"
  | "permissionGroups"
  | "manufacturerOrders"
  | "priceLists"
  | "productionOrders"
  | "productionPlans"
  | "productionOrdersWarehouseList"
  | "upholsteriesEmployees"
  | "drivers"
  | "driverRoutes"
  | "emailTemplates"
  | "statuses"
  | "labels"
  | "fiscalPrinters"
  | "tradingDocuments"
  | "businessEntities"
  | "vatRates"
  | "exchangeRates"
  | "signatureSchemas"
  | "manufacturingRoutes"
  | "manufacturingOrderGroups"
  | "salesInvoices"
  | "correctionInvoices"
  | "receipts"
  | "purchaseInvoices"
  | "myInvoices"
  | "orderGroupOrders"
  | "accountancyAccountsList"
  | "manufacturingPlansList"
  | "manufacturingSchemasList"
  | "manufacturingStagesList"
  | "manufacturingStageBoard"
  | "manufacturingStagePrioritiesList"
  | "manufacturingStageNormalList"
  | "manufacturingEmployeesList"
  | "manufacturingManufacturerOrders"
  | "manufacturingRawMaterials"
  | "manufacturingSemiFinishedMaterials"
  | "serviceProviderOrders"
  | "wmsWarehousemen"
  | "wmsUnloadings"
  | "externalManufacturingOrders"
  | "wmsPickings"
  | "wmsLoadings"
  | "wmsRamps"
  | "wmsBaskets"
  | "wmsReturns"
  | "externalManufacturerItems"
  | "externalManufacturerLineItems";

interface UiState {
  highlights: { [key in AvailableViewKeys]: number | string | null };
  viewTypes: { [key in AvailableViewKeys]: "list" | "board" };
  isNavbarOpened: boolean;
  isAppLoading: boolean;
  areCategoriesOnRouteOpen: boolean;
  isSingleItemQuantitiesPanelOpen: boolean;
  isUsingExtendedQuantityTableOnOrder: boolean;
  isRouteListOnCreatorOpen: boolean;
  communicationStatus: "busy" | "idle";
  versionLock: boolean;
  lastAssignedUsers: number[];
  shelfHeight: number;
  showUnpaidInvoiceToastr: boolean;
  warehouseSchemaZoom: number;
  viewNavigationState: string[];
}

// CONSTANTS & UTILS //

export const initialState: UiState = {
  communicationStatus: "busy",
  viewNavigationState: [],
  versionLock: false,
  isAppLoading: true,
  isUsingExtendedQuantityTableOnOrder: false,
  warehouseSchemaZoom: 13,
  highlights: {
    warehouseSchema: null,
    manufacturingOrderGroup: null,
    shippingCourier: null,
    profiles: null,
    manufacturer: null,
    manufacturingRoute: null,
    materials: null,
    materialsDemandAndAvailability: null,
    manufacturingRawMaterials: null,
    manufacturingSemiFinishedMaterials: null,
    manufacturingIndexes: null,
    freeSingleItemsQuantitiesDetails: null,
    externalManufacturingOrders: null,
    shippingShipments: null,
    orders: null,
    wmsBaskets: null,
    wmsReturns: null,
    products: null,
    routes: null,
    upholsteryOrders: null,
    offers: null,
    complaints: null,
    customers: null,
    attributes: null,
    orderGroups: null,
    orderGroupOrders: null,
    permissionGroups: null,
    manufacturerOrders: null,
    driverRoutes: null,
    upholsteriesEmployees: null,
    productionOrders: null,
    productionPlans: null,
    productionOrdersWarehouseList: null,
    callCenterRoutes: null,
    callCenterRouteOrders: null,
    callCenterOrders: null,
    logisticsRouteTradingDocuments: null,
    logisticsRoutes: null,
    logisticsRouteLineItems: null,
    warehousePackagesInSummary: null,
    warehousePackagesOutSummary: null,
    wmsCargoUnloads: null,
    drivers: null,
    emailTemplates: null,
    statuses: null,
    labels: null,
    fiscalPrinters: null,
    tradingDocuments: null,
    salesInvoices: null,
    purchaseInvoices: null,
    correctionInvoices: null,
    receipts: null,
    businessEntities: null,
    vatRates: null,
    exchangeRates: null,
    signatureSchemas: null,
    manufacturingRoutes: null,
    myInvoices: null,
    manufacturingOrderGroups: null,
    productSets: null,
    priceLists: null,
    accountancyAccountsList: null,
    logisticsCarriers: null,
    logisticsCarriersOrders: null,
    manufacturingPlansList: null,
    manufacturingSchemasList: null,
    manufacturingStagesList: null,
    manufacturingStageBoard: null,
    manufacturingStagePrioritiesList: null,
    manufacturingStageNormalList: null,
    manufacturingEmployeesList: null,
    manufacturingManufacturerOrders: null,
    serviceProviderOrders: null,
    wmsWarehousemen: null,
    wmsUnloadings: null,
    wmsPickings: null,
    wmsLoadings: null,
    wmsRamps: null,
    externalManufacturerItems: null,
    externalManufacturerLineItems: null,
    financesSoldPackages: null,
    financesSoldIndexes: null,
  },
  isNavbarOpened: true,
  areCategoriesOnRouteOpen: false,
  isSingleItemQuantitiesPanelOpen: false,
  isRouteListOnCreatorOpen: false,
  shelfHeight: 400,
  showUnpaidInvoiceToastr: false,
  viewTypes: {
    manufacturingOrderGroup: "list",
    warehouseSchema: "list",
    manufacturer: "list",
    profiles: "list",
    wmsBaskets: "list",
    wmsReturns: "list",
    shippingCourier: "list",
    freeSingleItemsQuantitiesDetails: "list",
    shippingShipments: "list",
    externalManufacturingOrders: "list",
    materials: "list",
    materialsDemandAndAvailability: "list",
    manufacturingRawMaterials: "list",
    manufacturingSemiFinishedMaterials: "list",
    manufacturingIndexes: "list",
    orders: "list",
    products: "list",
    routes: "list",
    driverRoutes: "list",
    upholsteryOrders: "list",
    offers: "list",
    complaints: "list",
    customers: "list",
    callCenterRoutes: "list",
    callCenterRouteOrders: "list",
    callCenterOrders: "list",
    logisticsRouteTradingDocuments: "list",
    logisticsRoutes: "list",
    logisticsRouteLineItems: "list",
    warehousePackagesInSummary: "list",
    warehousePackagesOutSummary: "list",
    wmsCargoUnloads: "list",
    attributes: "list",
    orderGroups: "list",
    permissionGroups: "list",
    orderGroupOrders: "list",
    manufacturerOrders: "list",
    upholsteriesEmployees: "list",
    productionOrders: "list",
    productionPlans: "list",
    productionOrdersWarehouseList: "list",
    drivers: "list",
    emailTemplates: "list",
    statuses: "list",
    labels: "list",
    fiscalPrinters: "list",
    tradingDocuments: "list",
    salesInvoices: "list",
    purchaseInvoices: "list",
    correctionInvoices: "list",
    receipts: "list",
    businessEntities: "list",
    vatRates: "list",
    exchangeRates: "list",
    signatureSchemas: "list",
    manufacturingRoutes: "list",
    manufacturingOrderGroups: "list",
    productSets: "list",
    myInvoices: "list",
    priceLists: "list",
    accountancyAccountsList: "list",
    logisticsCarriers: "list",
    logisticsCarriersOrders: "list",
    manufacturingPlansList: "list",
    manufacturingSchemasList: "list",
    manufacturingStagesList: "list",
    manufacturingStageBoard: "board",
    manufacturingStageNormalList: "list",
    manufacturingStagePrioritiesList: "list",
    manufacturingEmployeesList: "list",
    manufacturingManufacturerOrders: "list",
    serviceProviderOrders: "list",
    wmsWarehousemen: "list",
    wmsUnloadings: "list",
    wmsPickings: "list",
    wmsLoadings: "list",
    wmsRamps: "list",
    externalManufacturerItems: "list",
    externalManufacturerLineItems: "list",
    financesSoldPackages: "list",
    financesSoldIndexes: "list",
    manufacturingRoute: "list",
  },
  lastAssignedUsers: [],
};

// ACTIONS //

const highlight = createAction<{ name: AvailableViewKeys; id: number | string }>("ui/highlight");
const toggleNavbar = createAction("ui/toggleNavbar");
const toggleCategoriesOnRoutes = createAction("ui/toggleCategoriesOnRoutes");
const toggleSingleItemQuantitiesPanel = createAction("ui/toggleSingleItemQuantitiesPanel");
const toggleRouteListOnCreator = createAction("ui/toggleRouteListOnCreator");
const toggleIsUsingExtendedQuantityTableOnOrder = createAction(
  "ui/toggleIsUsingExtendedQuantityTableOnOrder",
);
const toggleViewType = createAction<{ type: AvailableViewKeys; value: "list" | "board" }>(
  "ui/toggleViewType",
);
const setCommunicationStatus = createAction<"busy" | "idle">("ui/setCommunicationStatus");
const changeWarehouseSchemaZoom = createAction<number>("ui/changeWarehouseSchemaZoom");
const enableVersionLock = createAction("ui/enableVersionLock");
const disableVersionLock = createAction("ui/disableVersionLock");
const rememberAssignedUser = createAction<number>("ui/rememberAssignedUser");
const setAppLoading = createAction<boolean>("ui/appLoading");
const setShelfHeight = createAction<number>("ui/shelfHeight");
const setShowUnpaidInvoiceToastr = createAction<boolean>("ui/showUnpaidInvoiceToastr");
const setViewNavigationState = createAction<string[]>("ui/setViewNavigationState");

export const actions = {
  highlight,
  toggleNavbar,
  toggleRouteListOnCreator,
  toggleCategoriesOnRoutes,
  toggleSingleItemQuantitiesPanel,
  toggleIsUsingExtendedQuantityTableOnOrder,
  toggleViewType,
  changeWarehouseSchemaZoom,
  enableVersionLock,
  disableVersionLock,
  rememberAssignedUser,
  setAppLoading,
  setShelfHeight,
  setShowUnpaidInvoiceToastr,
  setViewNavigationState,
};

// REDUCER //

export const reducer = createReduxReducer(initialState, {
  [highlight.type]: (state, action: ReturnType<typeof highlight>) => {
    state.highlights[action.payload.name] = action.payload.id;
  },
  [toggleNavbar.type]: (state, _action: ReturnType<typeof toggleNavbar>) => {
    state.isNavbarOpened = !state.isNavbarOpened;
  },
  [toggleIsUsingExtendedQuantityTableOnOrder.type]: (
    state,
    _action: ReturnType<typeof toggleIsUsingExtendedQuantityTableOnOrder>,
  ) => {
    state.isUsingExtendedQuantityTableOnOrder = !state.isUsingExtendedQuantityTableOnOrder;
  },
  [setAppLoading.type]: (state, action: ReturnType<typeof setAppLoading>) => {
    state.isAppLoading = action.payload;
  },
  [setShelfHeight.type]: (state, action: ReturnType<typeof setShelfHeight>) => {
    state.shelfHeight = action.payload;
  },
  [setShowUnpaidInvoiceToastr.type]: (
    state,
    action: ReturnType<typeof setShowUnpaidInvoiceToastr>,
  ) => {
    state.showUnpaidInvoiceToastr = action.payload;
  },
  [toggleCategoriesOnRoutes.type]: (
    state,
    _action: ReturnType<typeof toggleCategoriesOnRoutes>,
  ) => {
    state.areCategoriesOnRouteOpen = !state.areCategoriesOnRouteOpen;
  },
  [toggleSingleItemQuantitiesPanel.type]: (
    state,
    _action: ReturnType<typeof toggleSingleItemQuantitiesPanel>,
  ) => {
    state.isSingleItemQuantitiesPanelOpen = !state.isSingleItemQuantitiesPanelOpen;
  },
  [toggleRouteListOnCreator.type]: (
    state,
    _action: ReturnType<typeof toggleRouteListOnCreator>,
  ) => {
    state.isRouteListOnCreatorOpen = !state.isRouteListOnCreatorOpen;
  },

  [toggleViewType.type]: (state, action: ReturnType<typeof toggleViewType>) => {
    state.viewTypes[action.payload.type] = action.payload.value;
  },
  [setCommunicationStatus.type]: (state, action: ReturnType<typeof setCommunicationStatus>) => {
    state.communicationStatus = action.payload;
  },
  [enableVersionLock.type]: (state, action: ReturnType<typeof enableVersionLock>) => {
    state.versionLock = true;
  },
  [disableVersionLock.type]: (state, action: ReturnType<typeof disableVersionLock>) => {
    state.versionLock = false;
  },
  [rememberAssignedUser.type]: (state, action: ReturnType<typeof rememberAssignedUser>) => {
    if (state.lastAssignedUsers.includes(action.payload) === false) {
      state.lastAssignedUsers.unshift(action.payload);
      state.lastAssignedUsers.splice(3, state.lastAssignedUsers.length - 3);
    }
  },
  [changeWarehouseSchemaZoom.type]: (
    state,
    _action: ReturnType<typeof changeWarehouseSchemaZoom>,
  ) => {
    state.warehouseSchemaZoom = _action.payload;
  },
  [setViewNavigationState.type]: (state, _action: ReturnType<typeof setViewNavigationState>) => {
    state.viewNavigationState = _action.payload;
  },
  [resetStore.type]: () => initialState,
});
