import { customerActions } from "api/customers/actions";
import { useParams } from "react-router";
import { DefaultTransactionContent } from "./DefaultTransactionContent";
import { assertIsDefined } from "utilities/assertIsDefined";

export const DefaultTransactionDetails = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  assertIsDefined(customer);

  if (!customer.businessEntity) return null;

  return <DefaultTransactionContent customer={customer} />;
};
