import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedTradingDocumentItem } from "../salesInvoiceItems/SalesInvoiceItems";

export const useItemsColumns = () => {
  return useCreateTableColumns<NormalizedTradingDocumentItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => `${row.displayPosition}.`, {
        header: "#",
        size: 25,
      }),
      columnHelper.text(row => row.name, {
        header: "nazwa towaru",
        size: 180,
      }),
      columnHelper.number(row => row.quantity, {
        header: "liczba",
        size: 60,
        textAlign: "right",
      }),
      columnHelper.text(row => row.unit, {
        header: "jedn.",
        size: 50,
        textAlign: "right",
      }),
      columnHelper.amount(row => row.amountWithoutTax, {
        header: "c. netto",
        size: 85,
        amountDisplayProps: {
          decimal: {
            fontSize: "10",
            fontWeight: "700",
          },
          integer: {
            fontSize: "12",
            fontWeight: "700",
          },
        },
      }),
      columnHelper.amount(row => row.amountWithTax, {
        header: "c. brutto",
        size: 85,
        amountDisplayProps: {
          decimal: {
            fontSize: "10",
            fontWeight: "700",
          },
          integer: {
            fontSize: "12",
            fontWeight: "700",
          },
        },
      }),
      columnHelper.text(row => `${row.vatRate}%`, {
        header: "VAT",
        size: 85,
        textAlign: "right",
      }),
      columnHelper.amount(row => (row.quantity * row.amountWithoutTax).toFixed(2), {
        header: "war. netto",
        size: 85,
        amountDisplayProps: {
          decimal: {
            fontSize: "10",
            fontWeight: "700",
          },
          integer: {
            fontSize: "12",
            fontWeight: "700",
          },
        },
      }),
    ];
  });
};
