import { customerActions } from "api/customers/actions";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { useSelector } from "hooks";
import { useParams } from "react-router";
import { assertIsDefined } from "utilities/assertIsDefined";

export const ProductSets = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  const productSets = useSelector(store => store.partials.productSets);
  assertIsDefined(customer);

  const productSetsOptions = productSets.map(productSet => ({
    text: productSet.name,
    type: MenuItemType.TEXT,
    value: productSet.id,
  }));

  return (
    <div className="d-flex flex-column gap-3 px-1 pt-2 pb-5">
      <Select.Async
        className="w-50"
        items={productSetsOptions}
        mutationHook={customerActions.usePatchCustomer}
        selected={customer.productSet?.id ?? null}
        transformQueryData={productSet => {
          const selectedProductSet = productSets.find(_productSet => _productSet.id === productSet);
          assertIsDefined(selectedProductSet);
          return {
            id: customer.id,
            toUpdate: {
              productSet: selectedProductSet,
            },
          };
        }}
      />
    </div>
  );
};
