import { TradingDocument } from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { DeletePurchaseInvoicePosition } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/DeletePurchaseInvoicePosition";
import { EditPurchaseInvoicePosition } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/EditPurchaseInvoicePosition";
import { NormalizedTradingDocumentItem } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/salesInvoiceItems/SalesInvoiceItems";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useItemsColumns = (tradingDocument: TradingDocument) => {
  return useCreateTableColumns<NormalizedTradingDocumentItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "#",
        size: 35,
        cell: info => {
          const item = info.getValue();
          if (item.addedMethod === "MANUALLY")
            return (
              <EditPurchaseInvoicePosition
                inputPlaceholder="#"
                inputType="number"
                itemId={item.id}
                target={
                  <Typography fontSize="12" fontWeight="800">
                    {item.displayPosition}*.
                  </Typography>
                }
                targetKey="displayPosition"
                tradingDocumentId={tradingDocument.id}
                tradingDocumentItemId={item.id}
                value={item.displayPosition}
              />
            );
          return (
            <Typography fontSize="12" fontWeight="800">
              {item.displayPosition}.
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "nazwa towaru",
        size: 180,
        cell: info => {
          const item = info.getValue();
          if (item.addedMethod === "MANUALLY")
            return (
              <div className="d-flex align-items-center justify-content-between gap-2 w-100">
                <EditPurchaseInvoicePosition
                  inputPlaceholder="nazwa"
                  inputType="string"
                  itemId={item.id}
                  target={
                    <Typography fontSize="12" fontWeight="700">
                      {item.name}
                    </Typography>
                  }
                  targetKey="name"
                  tradingDocumentId={tradingDocument.id}
                  tradingDocumentItemId={item.id}
                  value={item.name}
                />
                <DeletePurchaseInvoicePosition
                  itemId={item.id}
                  tradingDocumentId={tradingDocument.id}
                  tradingDocumentItemId={item.id}
                  tradingDocumentItemName={item.name}
                />
              </div>
            );
          return (
            <Typography fontSize="12" fontWeight="700">
              {item.name}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "quantity",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            liczba
          </Typography>
        ),
        size: 50,
        cell: info => {
          const item = info.getValue();
          if (item.addedMethod === "MANUALLY")
            return (
              <EditPurchaseInvoicePosition
                inputPlaceholder="liczba"
                inputType="number"
                itemId={item.id}
                target={
                  <Typography className="w-100 text-right" fontSize="12" fontWeight="700">
                    {item.quantity}
                  </Typography>
                }
                targetKey="quantity"
                tradingDocumentId={tradingDocument.id}
                tradingDocumentItemId={item.id}
                value={item.quantity}
              />
            );
          return (
            <Typography className="w-100 text-right" fontSize="12" fontWeight="700">
              {item.quantity}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "unit",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            jedn.
          </Typography>
        ),
        size: 50,
        cell: info => {
          const item = info.getValue();
          if (item.addedMethod === "MANUALLY")
            return (
              <EditPurchaseInvoicePosition
                inputPlaceholder="jednostka"
                inputType="string"
                itemId={item.id}
                target={
                  <Typography className="w-100 text-right" fontSize="12" fontWeight="700">
                    {item.unit}
                  </Typography>
                }
                targetKey="unit"
                tradingDocumentId={tradingDocument.id}
                tradingDocumentItemId={item.id}
                value={item.unit}
              />
            );
          return (
            <Typography className="w-100 text-right" fontSize="12" fontWeight="700">
              {item.unit}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "amountWithoutTax",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            c. netto
          </Typography>
        ),
        size: 85,
        cell: info => {
          const item = info.getValue();
          if (item.addedMethod === "MANUALLY")
            return (
              <EditPurchaseInvoicePosition
                inputPlaceholder="cena netto"
                inputType="number"
                itemId={item.id}
                target={
                  <AmountDisplay
                    amount={String(item.amountWithoutTax)}
                    className="justify-content-end w-100"
                    integerTypographyProps={{
                      fontSize: "12",
                      fontWeight: "700",
                    }}
                    decimalTypographyProps={{
                      fontSize: "10",
                      fontWeight: "700",
                    }}
                  />
                }
                targetKey="amountWithoutTax"
                tradingDocumentId={tradingDocument.id}
                tradingDocumentItemId={item.id}
                value={item.amountWithoutTax}
              />
            );
          return (
            <AmountDisplay
              amount={String(item.amountWithoutTax)}
              className="justify-content-end w-100"
              integerTypographyProps={{
                fontSize: "12",
                fontWeight: "700",
              }}
              decimalTypographyProps={{
                fontSize: "10",
                fontWeight: "700",
              }}
            />
          );
        },
      }),
      columnHelper.amount(row => row.amountWithTax, {
        header: "c. brutto",
        size: 85,
        amountDisplayProps: {
          decimal: {
            fontSize: "10",
            fontWeight: "700",
          },
          integer: {
            fontSize: "12",
            fontWeight: "700",
          },
        },
      }),
      columnHelper.accessor(row => row, {
        id: "vatRate",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            VAT
          </Typography>
        ),
        size: 85,
        cell: info => {
          const item = info.getValue();
          if (item.addedMethod === "MANUALLY")
            return (
              <EditPurchaseInvoicePosition
                inputPlaceholder="vat"
                inputType="number"
                itemId={item.id}
                target={
                  <Typography className="w-100 text-right" fontSize="12" fontWeight="700">
                    {item.vatRate}%
                  </Typography>
                }
                targetKey="vatRate"
                tradingDocumentId={tradingDocument.id}
                tradingDocumentItemId={item.id}
                value={item.vatRate}
              />
            );
          return (
            <Typography className="w-100 text-right" fontSize="12" fontWeight="700">
              {item.vatRate}%
            </Typography>
          );
        },
      }),
      columnHelper.amount(row => (row.quantity * row.amountWithoutTax).toFixed(2), {
        header: "war. netto",
        size: 85,
        amountDisplayProps: {
          decimal: {
            fontSize: "10",
            fontWeight: "700",
          },
          integer: {
            fontSize: "12",
            fontWeight: "700",
          },
        },
      }),
    ];
  });
};
