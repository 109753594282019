import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer/Drawer";
import { SummarySection } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/summarySection/SummarySection";
import { PaymentDetails } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/tradingDocumentItems/PaymentDetails";
import { ImportedInvoiceItems } from "./ImportedInvoiceItems";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { useToggle } from "hooks";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { AddPurchaseInvoicePosition } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/components/AddPurchaseInvoicePosition";
import { getMaximumDisplayPosition } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/utils/getMaximumDisplayPosition";
import { PurchaseInvoiceImportPaymentForm } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/components/PurchaseInvoiceImportPaymentForm";

interface Props {
  tradingDocument: TradingDocument;
}

export const ItemsSection = ({ tradingDocument }: Props) => {
  const addPurchaseInvoicePosition = useToggle();

  if (!Boolean(tradingDocument.items.length)) return null;

  return (
    <RightPanelSection>
      <div className="pb-3">
        <ImportedInvoiceItems tradingDocument={tradingDocument} />
        <>
          <div className="py-2 d-flex align-items-center justify-content-end">
            <Button
              className="text-uppercase"
              onClick={addPurchaseInvoicePosition.open}
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Dodaj pozycję
            </Button>
          </div>
          {addPurchaseInvoicePosition.isOpen && (
            <AddPurchaseInvoicePosition
              close={addPurchaseInvoicePosition.close}
              maxDisplayPosition={getMaximumDisplayPosition(
                tradingDocument.items[0].tradingDocumentItems,
              )}
              tradingDocumentOrder={tradingDocument.items[0].id}
            />
          )}
        </>
        <SummarySection tradingDocument={tradingDocument} />
        <PaymentDetails
          customPaymentForm={
            <PurchaseInvoiceImportPaymentForm
              expectedPaymentForm={tradingDocument.expectedPaymentForm}
              id={tradingDocument.id}
              status={tradingDocument.status}
            />
          }
          tradingDocument={tradingDocument}
        />
      </div>
    </RightPanelSection>
  );
};
