import { customerActions } from "api/customers/actions";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { useSelector } from "hooks";
import { useParams } from "react-router";
import { assertIsDefined } from "utilities/assertIsDefined";

export const Marketplace = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  const salesAccounts = useSelector(store => store.partials.salesAccounts);
  assertIsDefined(customer);

  const salesAccountsOptions = salesAccounts.map(salesAccount => ({
    text: salesAccount.name,
    type: MenuItemType.TEXT,
    value: salesAccount.id,
  }));

  return (
    <div className="d-flex flex-column gap-3 px-1 pt-2 pb-5">
      <Select.Async
        className="w-50"
        items={salesAccountsOptions}
        mutationHook={customerActions.usePatchCustomer}
        selected={customer.salesAccount}
        transformQueryData={salesAccount => ({
          id: customer.id,
          toUpdate: {
            salesAccount: salesAccount as number,
          },
        })}
      />
    </div>
  );
};
