import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { LongHeldPackage, LongHeldPackageTab } from "api/unique-packages/models";
import { parseLocation } from "utilities/parseLocation";
import { uniquePackagesAction } from "api/unique-packages/actions";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateUtils, pluralize } from "utilities";
import { LoaderCell } from "components/miloDesignSystem/molecules/table/components/tableLoader/TableLoader";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { PropsWithChildren, useState } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import { AsyncInput, errorStatusToMessageDict } from "components/utils";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import styles from "./LongHeldPackages.module.css";
import { DatePicker } from "components/utils/datePicker";
import { Link } from "components/miloDesignSystem/atoms/link";

export const useLongHeldPackagesColumns = (tab: LongHeldPackageTab) => {
  return useCreateTableColumns<LongHeldPackage>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 350,
        typographyProps: { fontSize: "16", fontWeight: "700" },
      }),
      columnHelper.accessor(row => `${row.position}/${row.totalPackagesInCollection}`, {
        header: tab === LongHeldPackageTab["with-orders"] ? "nr paczki / z ilu paczek" : " ",
        size: tab === LongHeldPackageTab["with-orders"] ? 140 : 0,
        cell: info => {
          const _package: string = info.getValue();
          if (tab === LongHeldPackageTab["without-orders"]) return null;
          return (
            <Typography className="text-center w-100" fontSize="14" fontWeight="600">
              {_package}
            </Typography>
          );
        },
      }),
      columnHelper.text(
        row => (row.supplier && Boolean(row.supplier.name) ? row.supplier.name : null),
        {
          header: "kontrahent",
          size: 120,
          typographyProps: {
            fontSize: "14",
          },
        },
      ),
      columnHelper.date(row => row.created, {
        header: "od kiedy w mag.?",
        size: 98,
        typographyProps: { fontSize: "14", fontWeight: "500" },
      }),
      columnHelper.accessor(row => row.fee, {
        id: "startDate",
        header: () => (
          <Typography fontSize="12" fontWeight="400" color="neutralBlack48" className="ml-2">
            od kiedy liczyć opłatę?
          </Typography>
        ),
        size: 136,
        cell: info => {
          const feeId = info.getValue();
          return (
            <AsyncFetchCellWrapper field="startDate" id={feeId} className="ml-2">
              <DateColumn id={feeId} />
            </AsyncFetchCellWrapper>
          );
        },
      }),
      columnHelper.accessor(row => row.fee, {
        header: "dni",
        size: 50,
        cell: info => {
          const fee: number = info.getValue();
          return (
            <AsyncFetchCellWrapper field="days" id={fee}>
              <DaysColumn id={fee} />
            </AsyncFetchCellWrapper>
          );
        },
      }),
      columnHelper.accessor(row => row.order, {
        header: "zamówienie",
        size: 130,
        cell: info => {
          const order: LongHeldPackage["order"] = info.getValue();
          if (!order) return <EmptyValue fontSize="14" fontWeight="500" />;
          return (
            <div onClick={event => event.stopPropagation()}>
              <Link
                fontSize="14"
                fontWeight="500"
                to={`/orders/active/list/all?panelId=${order.id}`}
              >
                {order.signature}
              </Link>
            </div>
          );
        },
      }),
      columnHelper.text(row => (row.location ? parseLocation(String(row.location)).name : null), {
        header: "lokalizacja",
        size: 110,
        typographyProps: { fontSize: "14", fontWeight: "800" },
      }),
      columnHelper.text(
        row => {
          return `${row.length}x${row.width}x${row.height}`;
        },
        {
          header: "wymiary",
          size: 100,
          typographyProps: { fontSize: "14", fontWeight: "400" },
        },
      ),
      columnHelper.text(
        row => {
          return `${row.weight}kg`;
        },
        {
          header: "waga",
          size: 60,
          typographyProps: { fontSize: "14", fontWeight: "400" },
        },
      ),
      columnHelper.date(row => row.dateOfLastInvoice, {
        header: "ostatnia faktura",
        size: 92,
        typographyProps: { fontSize: "14", fontWeight: "700" },
      }),
      columnHelper.accessor(row => row.fee, {
        id: "dailyRate",
        header: () => (
          <Typography fontSize="12" fontWeight="400" color="neutralBlack48" className="ml-2">
            stawka dzienna
          </Typography>
        ),
        size: 100,
        cell: info => {
          const feeId = info.getValue();
          return (
            <AsyncFetchCellWrapper id={feeId} field="dailyRate" className="ml-2">
              <DailyRateColumn id={feeId} />
            </AsyncFetchCellWrapper>
          );
        },
      }),
      columnHelper.accessor(row => row.fee, {
        header: "saldo",
        size: 100,
        cell: info => {
          const feeId = info.getValue();
          return (
            <AsyncFetchCellWrapper id={feeId} field="totalBalance">
              <PaymentSummaryColumn id={feeId} field="totalBalance" />
            </AsyncFetchCellWrapper>
          );
        },
      }),
      columnHelper.accessor(row => row.fee, {
        header: "opłacone",
        size: 100,
        cell: info => {
          const feeId = info.getValue();
          return (
            <AsyncFetchCellWrapper id={feeId} field="paidBalance">
              <PaymentSummaryColumn id={feeId} field="paidBalance" />{" "}
            </AsyncFetchCellWrapper>
          );
        },
      }),
      columnHelper.accessor(row => row.fee, {
        header: "pozostało",
        size: 100,
        cell: info => {
          const feeId = info.getValue();
          return (
            <AsyncFetchCellWrapper id={feeId} field="unpaidBalance">
              <PaymentSummaryColumn id={feeId} field="unpaidBalance" />
            </AsyncFetchCellWrapper>
          );
        },
      }),
    ];
  });
};

const AsyncFetchCellWrapper = ({
  id,
  field,
  children,
  className,
}: PropsWithChildren<{
  id: number;
  field: "paidBalance" | "totalBalance" | "unpaidBalance" | "dailyRate" | "startDate" | "days";
  className?: string;
}>) => {
  const { data, isLoading, error } = uniquePackagesAction.useFees(id);

  if (isLoading) {
    return <LoaderCell height={mainListBigUiSchema.cell.height} width={94} />;
  }

  if (error?._httpStatus_ === 404) {
    return <EmptyValue fontSize="14" fontWeight="700" className={className} />;
  }
  if (error) {
    return (
      <>
        <MdiEmergencyHome color="deepOrange500" size="16" />
        <Typography fontSize="14" fontWeight="500" color="deepOrange500" className="ml-1" noWrap>
          {errorStatusToMessageDict[error._httpStatus_]}
        </Typography>
      </>
    );
  }

  if (!data?.[field] && field !== "dailyRate" && field !== "startDate") {
    return <EmptyValue fontSize="14" fontWeight="700" className={className} />;
  }
  return <>{children}</>;
};

const PaymentSummaryColumn = ({
  id,
  field,
}: {
  id: number;
  field: "paidBalance" | "totalBalance" | "unpaidBalance";
}) => {
  const { data: fee } = uniquePackagesAction.useFees(id);
  assertIsDefined(fee);

  return (
    <>
      <Typography fontSize="14" fontWeight="700" noWrap>
        {fee[field]}
      </Typography>
      <Typography fontSize="10" fontWeight="700" className="ml-1">
        {fee.currency}
      </Typography>
    </>
  );
};

const DailyRateColumn = ({ id }: { id: number }) => {
  const { data: fee } = uniquePackagesAction.useFees(id);
  const [isEditing, setIsEditing] = useState(false);
  assertIsDefined(fee);
  const patchFeeMutation = uniquePackagesAction.usePatchFee();
  const value = fee.dailyRate;

  if (isEditing) {
    return (
      <AsyncInput
        disabled={patchFeeMutation.isLoading}
        autoFocus
        inProgress={patchFeeMutation.isLoading}
        onChange={async value => {
          await patchFeeMutation.mutateAsync({
            id,
            toUpdate: { dailyRate: value },
          });
          setIsEditing(false);
        }}
        overwrites={{
          input: { className: styles.input },
        }}
        placeholder={value || ""}
        value={value || ""}
      />
    );
  }

  return (
    <div onClick={() => setIsEditing(true)} className={styles.editableCell}>
      <>
        <Typography fontSize="12" fontWeight="700" noWrap>
          {value || "0.00"}
        </Typography>
        <Typography fontSize="10" fontWeight="700" className="ml-1">
          {fee.currency}
        </Typography>
      </>
    </div>
  );
};

const DateColumn = ({ id }: { id: number }) => {
  const { data: fee } = uniquePackagesAction.useFees(id);
  const [isEditing, setIsEditing] = useState(false);
  assertIsDefined(fee);
  const patchStartDateMutation = uniquePackagesAction.usePatchFee();
  const value = fee.startDate;

  if (isEditing) {
    return (
      <DatePicker
        disabled={patchStartDateMutation.isLoading}
        autoFocus
        inProgress={patchStartDateMutation.isLoading}
        tabIndex={0}
        onChange={value => {
          patchStartDateMutation.mutateAsync({
            id,
            toUpdate: { startDate: value ? dateUtils.formatDateToIso(value) : null },
          });
          setIsEditing(false);
        }}
        overwrites={{
          popup: { className: styles.datePickerPopup },
          input: { className: styles.datePickerInput },
          container: { className: styles.datePickerContainer },
        }}
        placeholder={value || ""}
        value={value || ""}
      />
    );
  }

  return (
    <div onClick={() => setIsEditing(true)} className={styles.editableCell}>
      <div className="d-flex align-items-center gap-1">
        <Typography fontSize="14" fontWeight="700">
          {value ? dateUtils.formatDateToDisplay(value) : EMPTY_VALUE}
        </Typography>
      </div>
    </div>
  );
};

const DaysColumn = ({ id }: { id: number }) => {
  const { data: fee } = uniquePackagesAction.useFees(id);
  assertIsDefined(fee);

  return (
    <Typography fontSize="12" fontWeight="600">
      {Math.abs(fee.days)}{" "}
      {pluralize.pl(fee.days, {
        singular: "dzień",
        plural: "dni",
        other: "dni",
      })}
    </Typography>
  );
};
