import styles from "./TreeMenu.module.css";
import { useState } from "react";
import { ContentProps, Section, SidebarProps, TreeMenuProps } from "./types";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";
import { BaseIcon } from "components/miloDesignSystem/atoms/icons/types";
import { useIntersectionObserver } from "./useIntersectionObserver";

export const TreeMenu = ({ children, sections }: TreeMenuProps) => {
  const [activeSubsection, setActiveSubsection] = useState("");
  const [observerDisabled, setObserverDisabled] = useState(false);

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      setObserverDisabled(true);

      setTimeout(() => {
        setObserverDisabled(false);
      }, 650);
    }
  };

  return children({
    Content: (
      <Content
        sections={sections}
        observerDisabled={observerDisabled}
        setActiveSubsection={setActiveSubsection}
      />
    ),
    Sidebar: (
      <Sidebar
        activeSubsection={activeSubsection}
        handleScroll={handleScroll}
        sections={sections}
      />
    ),
  });
};

const Sidebar = ({
  activeSubsection,
  handleScroll,
  sections,
}: Pick<TreeMenuProps, "sections"> & SidebarProps) => {
  const handleSectionClick = (section: Section) => {
    if (section.subsections.length > 0) {
      handleScroll(section.subsections[0].id);
    }
  };

  const isSubsectionPartOfSection = (section: Section) =>
    section.subsections.some(subsection => subsection.id === activeSubsection);

  return (
    <div className={styles.sidebar}>
      {sections.map(section => (
        <div className={styles.section} key={section.id}>
          <div className={styles.sectionMenuHeader} onClick={() => handleSectionClick(section)}>
            {section.icon && (
              <Icon
                icon={section.icon}
                isSubsectionPartOfSection={isSubsectionPartOfSection(section)}
              />
            )}
            <Typography
              color={isSubsectionPartOfSection(section) ? "deepPurple500" : "neutralBlack76"}
              fontSize="14"
              fontWeight="700"
              noWrap
            >
              {section.label}
            </Typography>
          </div>
          {section.subsections.map(subsection => (
            <div
              key={subsection.id}
              className={styles.sidebarSubsectionWrapper}
              onClick={() => handleScroll(subsection.id)}
            >
              <div
                className={cx(styles.sidebarSubsection, {
                  [styles.activeSidebarSubsection]: activeSubsection === subsection.id,
                })}
              >
                <Typography
                  color={activeSubsection === subsection.id ? "deepPurple400" : "neutralBlack64"}
                  fontSize="12"
                  fontWeight="700"
                >
                  {subsection.label}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const Icon = ({
  icon: LabelIcon,
  isSubsectionPartOfSection,
}: {
  icon: JSX.Element | React.FunctionComponent<BaseIcon>;
  isSubsectionPartOfSection: boolean;
}) => {
  if (LabelIcon && typeof LabelIcon === "function")
    return (
      <LabelIcon color={isSubsectionPartOfSection ? "deepPurple400" : "neutralBlack48"} size="18" />
    );
  return null;
};

const Content = ({
  observerDisabled,
  sections,
  setActiveSubsection,
}: Pick<TreeMenuProps, "sections"> & ContentProps) => {
  const { sectionRefs } = useIntersectionObserver(observerDisabled, setActiveSubsection);

  return (
    <div className={styles.content}>
      {sections.map(section => (
        <div className="pt-2 pb-4" key={section.id}>
          <Typography
            className={styles.contentHeader}
            color="neutralBlack88"
            fontSize="18"
            fontWeight="700"
            noWrap
          >
            {section.label}
          </Typography>
          {section.content}
          {section.subsections.map(subsection => (
            <div
              className={styles.subsection}
              id={subsection.id}
              key={subsection.id}
              ref={el => (sectionRefs.current[subsection.id] = el)}
            >
              <Typography
                className="text-uppercase mb-2"
                color="neutralBlack48"
                fontSize="10"
                fontWeight="700"
              >
                {subsection.label}
              </Typography>
              {subsection.content}
            </div>
          ))}
        </div>
      ))}
      <div className={styles.spacer} />
    </div>
  );
};
