import { FinancesAmountSummary } from "api/trading-documents/models";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { ErrorType } from "hooks/createApiQuery";

interface Props {
  amountSummary: FinancesAmountSummary | null;
  amountSummaryIsLoading: boolean;
  error: ErrorType | null;
}

interface AmountDisplayProps {
  amount?: number;
  amountSummaryIsLoading: boolean;
  error: ErrorType | null;
}

export const AmountSummaryBottomBar = ({ amountSummary, amountSummaryIsLoading, error }: Props) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-end gap-1">
        <Typography color="neutralBlack48" fontSize="12" fontWeight="700">
          netto:
        </Typography>
        <SummaryAmount
          amountSummaryIsLoading={amountSummaryIsLoading}
          amount={amountSummary?.totalAmountWithoutTax}
          error={error}
        />
      </div>
      <div className="d-flex align-items-center justify-content-end gap-1">
        <Typography color="neutralBlack48" fontSize="12" fontWeight="700">
          VAT:
        </Typography>
        <SummaryAmount
          amountSummaryIsLoading={amountSummaryIsLoading}
          amount={amountSummary?.totalTax}
          error={error}
        />
      </div>
    </>
  );
};

const SummaryAmount = ({ amount, amountSummaryIsLoading, error }: AmountDisplayProps) => {
  if (amountSummaryIsLoading) return <Spinner size={16} />;

  if (amount === undefined || error)
    return (
      <Typography fontSize="16" fontWeight="700">
        brak danych
      </Typography>
    );

  return (
    <AmountDisplay
      amount={String(amount)}
      currency="PLN"
      decimalTypographyProps={{
        color: "neutralBlack100",
        fontSize: "16",
        fontWeight: "700",
      }}
      integerTypographyProps={{
        fontSize: "16",
        fontWeight: "700",
      }}
    />
  );
};
