import { customerActions } from "api/customers/actions";
import { Customer } from "api/customers/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik, FormikHelpers } from "formik";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";

interface Props {
  customer: Customer;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InitialValues {
  integrationToken: string;
  isConnected: boolean;
  url: string;
}

const initialValues: InitialValues = {
  integrationToken: "",
  isConnected: true,
  url: "",
};

export const ConnectAppWithCustomer = ({ customer, setIsSubmitting }: Props) => {
  const connectAppMutation = customerActions.usePatchCustomer();
  const handleSubmit = (values: InitialValues, actions: FormikHelpers<InitialValues>) => {
    connectAppMutation.mutate(
      {
        id: customer.id,
        toUpdate: { ...values },
      },
      {
        onSuccess: () => {
          actions.setSubmitting(false);
          setIsSubmitting(false);
        },
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
          <div className="d-flex flex-column gap-2">
            <TextField.Form<InitialValues>
              label="Klucz producenta"
              name="integrationToken"
              placeholder="Wpisz klucz producenta"
              size="small"
              type="password"
            />
            <TextField.Form<InitialValues>
              label="Adres Milo"
              name="url"
              placeholder="Wpisz URL"
              size="small"
            />
          </div>
          {!isSubmitting ? (
            <div className="d-flex align-items-center gap-3 py-3">
              <Button
                className="text-uppercase"
                onClick={() => setIsSubmitting(false)}
                size="small"
                variant="gray"
              >
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="small"
                type="submit"
                variant="deepPurple"
              >
                Połącz
              </Button>
            </div>
          ) : (
            <div className="d-flex align-items-center gap-2 p-3">
              <Typography color="deepPurple400" fontSize="12" fontWeight="700">
                trwa łączenie
              </Typography>
              <Spinner size={20} />
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};
