import { SetStateAction, useEffect, useRef } from "react";

export const useIntersectionObserver = (
  observerDisabled: boolean,
  setActiveSubsection: (value: SetStateAction<string>) => void,
) => {
  const sectionRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  useEffect(() => {
    if (observerDisabled) return;

    const observer = new IntersectionObserver(
      entries => {
        const visibleEntries = entries.filter(entry => entry.isIntersecting);

        if (visibleEntries.length > 0) {
          const closestEntry = visibleEntries.sort(
            (a, b) => a.target.getBoundingClientRect().top - b.target.getBoundingClientRect().top,
          )[0];

          setActiveSubsection(closestEntry.target.id);
        }
      },
      { threshold: [0.5], rootMargin: "-40px 0px 0px 0px" },
    );

    Object.values(sectionRefs.current).forEach(section => {
      if (section) observer.observe(section);
    });

    return () => {
      Object.values(sectionRefs.current).forEach(section => {
        if (section) observer.unobserve(section);
      });
    };
  }, [setActiveSubsection, observerDisabled]);

  return { sectionRefs };
};
