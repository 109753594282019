import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import styles from "../OrdersSection.module.css";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useState } from "react";
import { useParams } from "react-router";
import { customerActions } from "api/customers/actions";
import { assertIsDefined } from "utilities/assertIsDefined";
import { AddressCard } from "./AddressCard";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";
import { MdiKeyboardArrowUp } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowUp";

export const DeliveryAddresses = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  assertIsDefined(customer);
  const [showMoreAddresses, setShowMoreAddresses] = useState(false);

  return (
    <>
      <div className={styles.addDeliveryBtn}>
        <Button
          className="text-uppercase mb-2"
          disabled
          onClick={() => {}}
          size="small"
          startIcon={MdiAdd}
          variant="gray"
        >
          Dodaj adres dostawy
        </Button>
      </div>
      <div className="d-flex flex-column gap-3 px-1 pt-2 pb-1 mb-3">
        <div className="d-flex flex-wrap gap-2">
          {(showMoreAddresses ? customer.addresses : customer.addresses.slice(0, 3)).map(
            address => (
              <AddressCard address={address} customer={customer} key={address.id} />
            ),
          )}
        </div>
        {Boolean(customer.addresses.length) && (
          <div>
            <Button
              className="text-uppercase"
              endIcon={showMoreAddresses ? MdiKeyboardArrowUp : MdiKeyboardArrowDown}
              onClick={() => setShowMoreAddresses(prev => !prev)}
              size="small"
              variant="transparent"
            >
              {showMoreAddresses ? "Ukryj dodatkowe adresy" : "Pokaż więcej adresów"}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
