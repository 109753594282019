import { TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { StateLabel } from "components/common/stateLabel";
import { RightPanelSection } from "components/utils/drawer";
import { dateUtils, handleDateField, pluralize } from "utilities";
import styles from "../RightPanel.module.css";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { useState } from "react";
import cx from "classnames";
import { UserAvatar } from "components/utils/userAvatar";
import { AssignedUser } from "components/common/assignUser/AssignUser";
import { LinkToPage } from "components/utils/linkToPage";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { BuyerSection } from "./BuyerSection";

interface Props {
  tradingDocument: TradingDocument;
}

export const GeneralInfoSection = ({ tradingDocument }: Props) => {
  const [showAllOrders, setShowAllOrders] = useState(false);
  const changeAssignedUserMutation = usePatchTradingDocumentMutation();
  const isNotSalesAndNotProformaAndNotAdvance =
    tradingDocument.invoiceType !== "SALES" &&
    tradingDocument.invoiceType !== "PROFORMA" &&
    tradingDocument.invoiceType !== "ADVANCE";
  const isCorrection = tradingDocument.invoiceType === "CORRECTION";

  return (
    <RightPanelSection>
      <div className="d-flex align-items-start justify-content-between">
        <div>
          <InfoLabel title="przypisano do">
            <AssignedUser
              value={tradingDocument?.assignedTo}
              onChange={value =>
                changeAssignedUserMutation.mutate({
                  assignedTo: value,
                  id: tradingDocument.id,
                })
              }
            />
          </InfoLabel>
          <InfoLabel title="XML pobrano">
            {tradingDocument.xmlOptimaDownloadBy?.firstName ? (
              <>
                {tradingDocument.xmlOptimaDownloadBy.avatar ? (
                  <UserAvatar
                    className="mr-1"
                    avatarSrc={tradingDocument.xmlOptimaDownloadBy.avatar}
                  />
                ) : null}
                &nbsp;
                {tradingDocument.xmlOptimaDownloadBy.firstName}&nbsp;
                {tradingDocument.xmlOptimaDownloadBy.lastName} &nbsp; &nbsp;
              </>
            ) : null}
            {handleDateField(tradingDocument.xmlOptimaDownloadAt)}
          </InfoLabel>
          <InfoLabel title="data wystawienia">
            {isNotSalesAndNotProformaAndNotAdvance && !isCorrection ? (
              <Typography fontSize="14" fontWeight="500">
                {handleDateField(tradingDocument.invoiceIssueDate)}
              </Typography>
            ) : (
              <DatePicker.Async
                isNullable
                mutationHook={usePatchTradingDocumentMutation}
                transformQueryData={date => ({
                  id: tradingDocument.id,
                  invoiceIssueDate: date ? dateUtils.formatDateToIso(date) : null,
                  pitTaxObligationDate: date ? dateUtils.formatDateToIso(date) : null,
                  vatTaxObligationDate: date ? dateUtils.formatDateToIso(date) : null,
                })}
                value={tradingDocument.invoiceIssueDate}
              />
            )}
          </InfoLabel>
          <InfoLabel title="data sprzedaży">
            {isNotSalesAndNotProformaAndNotAdvance && !isCorrection ? (
              <Typography fontSize="14" fontWeight="700">
                {handleDateField(tradingDocument.invoiceDeliveryDate)}
              </Typography>
            ) : (
              <DatePicker.Async
                isNullable
                mutationHook={usePatchTradingDocumentMutation}
                transformQueryData={date => ({
                  id: tradingDocument.id,
                  invoiceDeliveryDate: date ? dateUtils.formatDateToIso(date) : null,
                  pitTaxObligationDate: date ? dateUtils.formatDateToIso(date) : null,
                  vatTaxObligationDate: date ? dateUtils.formatDateToIso(date) : null,
                })}
                value={tradingDocument.invoiceDeliveryDate}
              />
            )}
          </InfoLabel>
          <InfoLabel title="data utworzenia">
            <Typography fontSize="14" fontWeight="700">
              {handleDateField(tradingDocument.createdAt)}
            </Typography>
          </InfoLabel>
          <InfoLabel title="data płatności">
            <Typography fontSize="14" fontWeight="700">
              {handleDateField(tradingDocument.invoicePaymentDate)}
            </Typography>
          </InfoLabel>
          <InfoLabel title="sprzedawca">
            <StateLabel kind="orangeDark">
              <Typography fontSize="10" fontWeight="600">
                {Boolean(tradingDocument.sellerCompanyName)
                  ? `${tradingDocument.sellerCompanyName}`
                  : `${tradingDocument.sellerFirstName} ${tradingDocument.sellerLastName}`}
              </Typography>
            </StateLabel>
            <div className="d-flex align-items-center gap-1">
              <Typography color="grey400" fontSize="14" fontWeight="600">
                NIP:
              </Typography>
              <Typography fontSize="14" fontWeight="600">
                {Boolean(tradingDocument.seller?.taxId)
                  ? `${tradingDocument.seller?.taxId}`
                  : EMPTY_VALUE}
              </Typography>
            </div>
          </InfoLabel>

          <BuyerSection tradingDocument={tradingDocument} />

          <InfoLabel title="termin płatności">
            <div className="d-flex gap-2">
              {tradingDocument.invoiceType !== "SALES" && !isCorrection ? (
                <Typography fontSize="16" fontWeight="700">
                  {handleDateField(tradingDocument.paymentDeadline)}
                </Typography>
              ) : (
                <DatePicker.Async
                  isNullable
                  mutationHook={usePatchTradingDocumentMutation}
                  transformQueryData={date => ({
                    id: tradingDocument.id,
                    paymentDeadline: date ? dateUtils.formatDateToIso(date) : null,
                  })}
                  value={tradingDocument.paymentDeadline}
                />
              )}
              {tradingDocument.basePaymentDeadlineDelta && (
                <div className="d-flex align-items-center">
                  <StateLabel kind="cyan">
                    <span>
                      {tradingDocument.basePaymentDeadlineDelta}{" "}
                      {pluralize.pl(tradingDocument.basePaymentDeadlineDelta, {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      })}
                    </span>
                  </StateLabel>
                </div>
              )}
            </div>
          </InfoLabel>
          <InfoLabel title="zamówienia">
            {Boolean(tradingDocument.orders?.length) &&
              !showAllOrders &&
              tradingDocument.orders.slice(0, 2).map((order, index) => {
                if (order !== null) {
                  return (
                    <LinkToPage
                      content={
                        <>
                          <div className="fs-14 fw-500">{order.signature}</div>
                          {index + 1 !== tradingDocument.orders.length &&
                            tradingDocument.orders[index + 1] !== null &&
                            index < 1 && <div>,</div>}
                        </>
                      }
                      key={order.id}
                      url={getOrderRedirectUrl(order)}
                    />
                  );
                }
                return null;
              })}
            {Boolean(tradingDocument.orders?.length) && showAllOrders && (
              <div className={cx(styles.showAllOrders)}>
                <div className="d-flex align-items-center flex-wrap">
                  {tradingDocument.orders.map((order, index) => {
                    if (order !== null) {
                      return (
                        <LinkToPage
                          content={
                            <>
                              <div className="fs-14 fw-500">{order.signature}</div>
                              {index + 1 !== tradingDocument.orders.length &&
                                tradingDocument.orders[index + 1] !== null && <div>,&nbsp;</div>}
                            </>
                          }
                          url={getOrderRedirectUrl(order)}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
                <Button
                  className="mt-2 text-uppercase"
                  onClick={() => setShowAllOrders(prev => !prev)}
                  size="small"
                  variant="gray"
                >
                  Ukryj
                </Button>
              </div>
            )}
            {tradingDocument.orders.filter(order => order).length - 2 > 0 && !showAllOrders && (
              <div onClick={() => setShowAllOrders(prev => !prev)}>
                <MoreDataCounter
                  className={styles.moreDataCounterOverride}
                  counter={tradingDocument.orders.filter(order => order).length - 2}
                />
              </div>
            )}
          </InfoLabel>
          {tradingDocument.invoiceType === "SALES" && (
            <InfoLabel title="proforma">
              {tradingDocument.proforma ? (
                <LinkToPage
                  content={
                    <Typography color="inherit" fontSize="14" fontWeight="600">
                      {tradingDocument.proforma.signature}
                    </Typography>
                  }
                  url={`/finances/proformas/list/all?panelId=${tradingDocument.proforma.id}`}
                />
              ) : (
                <EmptyValue />
              )}
            </InfoLabel>
          )}
        </div>
      </div>
    </RightPanelSection>
  );
};
