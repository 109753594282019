import { customerActions } from "api/customers/actions";
import { useLabels } from "api/labels/hooks";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Select } from "components/miloDesignSystem/molecules/select";
import { CommonError } from "components/utils";
import { useParams } from "react-router";
import { assertIsDefined } from "utilities/assertIsDefined";

export const OrderLabel = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  const { data: labels, error, isLoading } = useLabels("");
  assertIsDefined(customer);

  if (isLoading)
    return (
      <div className="d-flex flex-column gap-4 px-1 pt-2 pb-1">
        <Spinner size={26} />
      </div>
    );

  if (error)
    return (
      <div className="d-flex flex-column gap-4 px-1 pt-2 pb-1">
        <CommonError status={error._httpStatus_} />
      </div>
    );

  if (!labels) return null;

  return (
    <div className="d-flex flex-column gap-4 px-1 pt-2 pb-1">
      <Checkbox.Async
        checked={customer.isVisibleOnLabel}
        label="Pokazuj nazwę producenta na etykiecie"
        mutationHook={customerActions.usePatchCustomer}
        size="sm"
        transformQueryData={isVisibleOnLabel => ({
          id: customer.id,
          toUpdate: {
            isVisibleOnLabel,
          },
        })}
      />
      <Select.Async
        className="w-50"
        items={labels.map(label => ({
          text: `Wzór ${label.letter}`,
          type: MenuItemType.TEXT,
          value: label.id,
        }))}
        label="Wzór etykiety"
        mutationHook={customerActions.usePatchCustomer}
        selected={customer.labelTemplate?.id ?? null}
        transformQueryData={labelTemplate => {
          const selectedLabel = labels.find(label => label.id === labelTemplate);
          assertIsDefined(selectedLabel);
          return {
            id: customer.id,
            toUpdate: {
              labelTemplate: selectedLabel,
            },
          };
        }}
      />
    </div>
  );
};
