import { useFilters } from "hooks/useFilters";
import { useOrderListColumns } from "./useOrderListColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { cx } from "utilities";
import styles from "pages/wms/shared/Wms.module.css";
import { orderActions } from "api/orders/actions";
import { ManufacturingOrder } from "api/orders/models";
import { ManufacturingStatus } from "api/routes/models";
import { EmptySection } from "components/common/emptySection/EmptySection";

interface Props {
  resourceId: number;
  resourceType: "orderGroup" | "route";
}

export const OrderList = ({ resourceId, resourceType }: Props) => {
  const { searchParams, setFilter } = useFilters({ page: 1, [resourceType]: resourceId });
  const {
    data: orders,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = orderActions.useManufacturingOrders(searchParams);
  const columns = useOrderListColumns();

  return (
    <div className="pb-2 mt-2">
      <Table<ManufacturingOrder>
        rows={orders}
        columns={columns}
        onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
        isLoading={isLoading || isPreviousData}
        error={error}
        uiSchema={comfortableListUiSchema}
        pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
        overrides={() => {
          return {
            row: row => {
              return {
                className: cx({
                  [styles.finishedItem]: row.manufacturingStatus === ManufacturingStatus.FINISHED,
                  [styles.inProgressItem]:
                    row.manufacturingStatus === ManufacturingStatus.IN_PROGRESS,
                }),
              };
            },
            hideHeader: !orders.length,
            noResultComponent: (
              <div className="mt-2">
                <EmptySection label="Brak zamówień" />
              </div>
            ),
          };
        }}
      />
    </div>
  );
};
