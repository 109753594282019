import { tradingDocumentsActions } from "api/trading-documents/actions";
import { useTradingDocument } from "api/trading-documents/hooks";
import {
  BulkSalesInvoiceConfirmPreview,
  CreateCorrectionModalState,
} from "api/trading-documents/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useStateModal } from "hooks";
import { DownloadRevisor } from "pages/tradingDocuments/shared/components/DownloadRevisor";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import { MdiAdd } from "../../../../../components/miloDesignSystem/atoms/icons/MdiAdd";
import { CreateCorrectionManuallyModal } from "pages/tradingDocuments/salesInvoicesLIst/rightPanel/components/createCorrectionManuallyModal/CreateCorrectionManuallyModal";

interface Props {
  close: () => void;
}

export const PanelHeader = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: advance } = useTradingDocument({ id: panelId });
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const handleAdvancePdfDownload = tradingDocumentsActions.useAdvanceInvoicePdfDownload();
  const { isTriggerElementHidden } = useScrollableContext();
  const createCorrectionManuallyModal = useStateModal<CreateCorrectionModalState | null>();
  const previewCorrectionMutation = tradingDocumentsActions.useCorrectionPreview(
    createCorrectionManuallyModal.open,
  );

  if (!advance) return null;

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <ConfirmCheckbox tradingDocument={advance} replyModal={replyModal} />
            <div>Faktura zaliczkowa&nbsp;{advance.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <ConfirmTradingDocument tradingDocument={advance} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          {advance.status === "CONFIRMED" && (
            <Button
              className="text-uppercase mr-1"
              isLoading={previewCorrectionMutation.isLoading}
              onClick={() =>
                previewCorrectionMutation.mutate({
                  id: advance.id,
                  expectedPaymentForm: advance.expectedPaymentForm,
                })
              }
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Utwórz korektę
            </Button>
          )}
          <Tooltip title="Pobierz PDF z danymi faktury zaliczkowej">
            <IconButton
              icon={<MdiDownloadPdf size="18" />}
              onClick={() => handleAdvancePdfDownload(advance)}
              variant="transparent"
            />
          </Tooltip>
          <DownloadRevisor tradingDocumentIds={[advance.id]} />

          <MoreInfoButton close={close} invoice={advance} panelId={panelId} />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      <CreateCorrectionManuallyModal
        close={createCorrectionManuallyModal.close}
        isOpen={createCorrectionManuallyModal.isOpen}
        state={createCorrectionManuallyModal.state}
        type="ADVANCE"
      />
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
    </>
  );
};
