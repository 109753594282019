import { Order } from "api/orders/models";
import { InfoLabel } from "../infoLabel";
import { marketplaceStatusesActions } from "api/marketplace-statuses/actions";
import { queryString } from "utilities";
import { DesiredStatusChoices, MarketplaceStatusAttempt } from "api/marketplace-statuses/models";
import { marketplaceStatusesUtilities } from "pages/orders/ordersList/rightPanel/marketplaceStatusesSection/utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiRepeat } from "components/miloDesignSystem/atoms/icons/MdiRepeat";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  orderId: Order["id"];
}

export const MarketplaceShipping = ({ orderId }: Props) => {
  const search = queryString.stringify({ orderId, desiredStatus: DesiredStatusChoices.SENT });
  const { data, isLoading } = marketplaceStatusesActions.useGetMarketplaceStatusesAttempts(search);

  const lastStatus = data[0];
  if (isLoading) {
    return (
      <InfoLabel title="status przekazania nr przesyłki" className="mt-3">
        <Spinner size={20} />
      </InfoLabel>
    );
  }

  if (!lastStatus) {
    return (
      <InfoLabel title="status przekazania nr przesyłki" className="mt-3">
        <Typography fontSize="14" fontWeight="600">
          brak statusu
        </Typography>
      </InfoLabel>
    );
  }

  return (
    <InfoLabel title="status przekazania nr przesyłki" className="mt-3">
      {marketplaceStatusesUtilities.getProcessingStatusTag(lastStatus.statusEvent.processingStatus)}{" "}
      <ErrorStatus status={lastStatus} />
    </InfoLabel>
  );
};

const ErrorStatus = ({ status }: { status: MarketplaceStatusAttempt }) => {
  const retryMutation = marketplaceStatusesActions.usePostMarketplaceStatusesRetry();
  if (!status.error) return null;
  return (
    <Button
      onClick={() => retryMutation.mutate({ statusEventId: status.statusEvent.id })}
      isLoading={retryMutation.isLoading}
      variant="transparent"
      size="small"
      className="text-uppercase"
      endIcon={MdiRepeat}
    >
      Synchronizuj
    </Button>
  );
};
