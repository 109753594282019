import { useState } from "react";
import styles from "./ConfirmCollectionDate.module.css";
import cx from "classnames";
import dateIcon from "assets/images/4.svg";
import timeIcon from "assets/images/46.svg";
import confirmIcon from "assets/images/7.svg";
import confirmedIcon from "assets/images/7c.svg";
import logoIcon from "assets/images/logo2.svg";
import { RouteComponentProps } from "react-router";
import { dateFns, getAnyErrorKey } from "utilities";
import {
  CommonError,
  Spinner,
  StatusHandlerHelpers,
  StatusHandler,
  ErrorMessage,
} from "components/utils";
import { useAsyncActions, useDidMount } from "hooks";
import { confirmOrderCollectionDate, getOrderCollectionDateConfirmInfo } from "api/orders/calls";
import { OrderCollectionDateConfirmation } from "api/orders/models";
import { Button } from "components/common";
import { getOwner, useOwnerConfig } from "hooks/useOwnerConfig";

type Props = RouteComponentProps<{ orderId: string }>;
type InitialState = {
  [K in keyof OrderCollectionDateConfirmation]: OrderCollectionDateConfirmation[K] | null;
};

const initialState: InitialState = {
  collectionDate: null,
  deliveryConfirmationDeadline: null,
  plannedCollectionHour: null,
  maximumCollectionHour: null,
  minimumCollectionHour: null,
  email: null,
  phone: null,
  officeOpeningHours: null,
  language: null,
  order: null,
};

const trans = {
  "Prosimy o potwierdzenie daty odbioru": {
    pl: null,
    de: "Bitte bestätigen Sie das Liefertermin",
  },
  "ikona kalendarza": {
    pl: null,
    de: "Kalendersymbol",
  },
  "ikona zegara": {
    pl: null,
    de: "Uhrensymbol",
  },
  data: {
    pl: null,
    de: "Datum",
  },
  godzina: {
    pl: null,
    de: "Stunde",
  },
  "szacujemy, że przesyłka dotrze ok.": {
    pl: null,
    de: "Wir schätzen, dass das Paket ca.",
  },
  "Godziny otwarcia biura:": {
    pl: null,
    de: "Büroöffnungszeiten:",
  },
  "Potwierdzam datę odbioru": {
    pl: null,
    de: "Ich bestätige den Liefertermin",
  },
  "Przypominamy, że kierowca nie ma obowiązku wniesienia towaru.": {
    pl: null,
    de:
      "Wir weisen Sie darauf hin, dass der Fahrer nicht verpflichtet ist, die Ware hoch zu tragen.",
  },
  "W celu zapewnienia dowozu, należy potwierdzić dostawę do dnia:": {
    pl: null,
    de: "Um die Zustellung zu gewährleisten, bestätigen Sie die Zustellung bis:",
  },
  "Dziękujemy!": {
    pl: null,
    de: "Danke!",
  },
  "Data odbioru została potwierdzona.": {
    pl: null,
    de: "Der Abholtermin wurde bestätigt",
  },
  "ikona potwierdzono": {
    pl: null,
    de: "Symbol bestätigt",
  },
  "Potwierdzenie daty odbioru nie jest dłużej możliwe": {
    pl: null,
    de: "Das Abholdatum kann nicht mehr bestätigt werden",
  },
  "Data została już potwierdzona.": {
    pl: null,
    de: "Das Datum wurde bereits bestätigt.",
  },
  "Potrzebujesz zmienić datę odbioru przesyłki?": {
    pl: null,
    de: "Können Sie den Liefertermin nicht warnehmen?",
  },
  "Skontaktuj się z naszym działem handlowym": {
    pl: null,
    de: "Melden Sie sich an die Spedition",
  },
};

const useTrans = (lang: "pl" | "de" | null) => (phrase: keyof typeof trans) => {
  const language = lang || (navigator.language as "pl" | "de");
  return trans[phrase][language] || phrase;
};

export const ConfirmCollectionDate = ({ match }: Props) => {
  const { orderId } = match.params;
  const [status, setStatus] = useState(0);
  const [language, setLanguage] = useState<"pl" | "de" | null>(null);
  const [state, { request, success, failure }] = useAsyncActions({
    initialState: { result: initialState },
  });
  const { result, error, fetching } = state;
  const t = useTrans(language);
  const ownerConfig = useOwnerConfig().confirmCollectionDate;

  useDidMount(async () => {
    request();
    const [payload, err, { status }] = await getOrderCollectionDateConfirmInfo(orderId);
    if (payload) {
      setStatus(status);
      setLanguage(payload.language === "at" ? "de" : payload.language);
      success(payload);
    } else if (err) {
      setLanguage(err.language as "pl" | "de");
      setStatus(status);
      failure({ status });
    }
  });

  async function handleConfirmCollectionDate(helpers: StatusHandlerHelpers) {
    helpers.startFetching();
    const [, error, { status }] = await confirmOrderCollectionDate(orderId);
    helpers.stopFetching();

    if (!error) {
      setStatus(status);
    } else {
      helpers.stopFetching({ message: getAnyErrorKey(error) });
    }
  }

  const owner = getOwner();

  if (error && error.status !== 403) {
    return <CommonError status={error.status} text={getAnyErrorKey(error)} />;
  }

  if (!status || fetching) {
    return <Spinner color="blue" size="big" position="absolute" />;
  }

  const pakkoTotalAmount = () => {
    if (owner === "pakko" && result.order) {
      const { order } = result;
      const itemsAmount: number = order.items.reduce(
        (acc, item) => acc + Number(item.amount) * item.quantity,
        0,
      );

      const totalItemsAmount: number =
        itemsAmount +
        Number(order.delivery.amount) -
        Number(order.payment.discount) -
        Number(order.payment.paidAmount);

      const totalAmountBasedOnDropshipping: number =
        Number(order.payment.amountToCollect) -
        Number(order.payment.discount) -
        Number(order.payment.paidAmount);

      return order.customer?.hasAmountToCollect ? totalAmountBasedOnDropshipping : totalItemsAmount;
    }
  };

  const pageContentPerStatus: Record<
    number,
    { title: string; subTitle: string; main: JSX.Element }
  > = {
    // fetched order collection date data status
    200: {
      title: t("Prosimy o potwierdzenie daty odbioru"),
      subTitle: "",
      main: (
        <>
          <div className={cx(styles.dateFrame, "d-flex row justify-content-between mb-5")}>
            <div className="col-12 col-md-5 mr-4 p-2">
              <div className={styles.label}>
                <span className={styles.labelIcon}>
                  <img src={dateIcon} alt={t("ikona kalendarza")} />
                </span>
                <span>{t("data")}</span>
              </div>
              <div className={styles.value}>
                <span className="text-nowrap">
                  {result.collectionDate &&
                    dateFns.format(new Date(result.collectionDate), "dd-MM-yyyy")}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 p-2">
              <div className={styles.label}>
                <span className={styles.labelIcon}>
                  <img src={timeIcon} alt={t("ikona zegara")} />
                </span>
                <span>{t("godzina")}</span>
              </div>
              <div className={styles.value}>
                <span className="text-nowrap">
                  {result.minimumCollectionHour && result.minimumCollectionHour}–
                  {result.maximumCollectionHour && result.maximumCollectionHour}
                </span>
              </div>
            </div>
          </div>
          {result.deliveryConfirmationDeadline && (
            <div className="d-flex align-items-center justify-content-center mb-4">
              <span className="">
                {t("W celu zapewnienia dowozu, należy potwierdzić dostawę do dnia:")}
              </span>
              <span className="text-color-grey">
                &nbsp;
                {dateFns.format(new Date(result.deliveryConfirmationDeadline), "dd.MM.yyyy, HH:mm")}
              </span>
            </div>
          )}
          <div className="d-flex justify-content-center ">
            <StatusHandler>
              {helpers => (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <Button
                    type="button"
                    kind="primary"
                    size="large"
                    disabled={helpers.isFetching}
                    className={styles.confirmButton}
                    onClick={() => {
                      handleConfirmCollectionDate(helpers);
                    }}
                  >
                    <img src={confirmIcon} alt="ikona potwierdź" />
                    <span>{t("Potwierdzam datę odbioru")}</span>
                  </Button>
                  <ErrorMessage
                    type="text"
                    text={helpers.errors.message}
                    className="mx-auto w-auto mb-2"
                  />
                  {owner !== "pakko" && (
                    <p className={styles.paragraph}>
                      {t("Przypominamy, że kierowca nie ma obowiązku wniesienia towaru.")}
                    </p>
                  )}
                  {owner === "ec" && language === "de" && (
                    <p className={cx(styles.importantParagraph, "mt-4")}>
                      (Möbel/Wohnungsausstattung) Lieferung erfolgt nur bis zur freien
                      Bordsteinkante. {result.order && result.order.payment.amountToCollect}{" "}
                      {result.order && result.order.payment.currency} . ECE Logistics +49 160
                      94732122 (MO-FR) 8-16
                    </p>
                  )}
                </div>
              )}
            </StatusHandler>
          </div>
        </>
      ),
    },
    // confirm collection date success
    201: {
      title: t("Dziękujemy!"),
      subTitle: t("Data odbioru została potwierdzona."),
      main: <img src={confirmedIcon} alt={t("ikona potwierdzono")} className={styles.bigIcon} />,
    },
    // expired token
    403: {
      title: t("Potwierdzenie daty odbioru nie jest dłużej możliwe"),
      subTitle: t("Data została już potwierdzona."),
      main: (
        <img
          src={timeIcon}
          alt={t("ikona zegara")}
          className={styles.bigIcon}
          style={{ opacity: "0.21" }}
        />
      ),
    },
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div
          className={cx(
            styles.contentWrapper,
            "d-flex align-items-center justify-content-between flex-column",
          )}
        >
          <div className="px-3 py-5 d-flex flex-column align-items-center">
            {owner === "pakko" && status !== 403 && (
              <div>
                <h4 className="mb-5 font-weight-semi-bold">
                  {result.order?.delivery?.firstName}, wkrótce otrzymasz przesyłkę o numerze:{" "}
                  <div className={cx("mt-2", styles.bigLabel)}>
                    <strong>{result.order?.signature}</strong>
                  </div>
                </h4>
                <div className={styles.deliveryLabel}>Adres dostawy</div>
                <h5 className={styles.deliveryName}>
                  <strong>
                    {result.order?.delivery.firstName} {result.order?.delivery.lastName}
                  </strong>
                </h5>
                <div className={styles.delivery}>{result.order?.delivery.street}</div>
                <div className={styles.delivery}>
                  {result.order?.delivery.postCode}, {result.order?.delivery.city}
                </div>
                <div className={styles.delivery}>tel.: {result.order?.delivery.phone}</div>
                <div className={styles.delivery}>Email: {result.order?.delivery.email}</div>
                <div className={cx("mt-5", styles.desc)}>
                  <div>Dzień dobry!</div>
                  <div>
                    Zaplanowaliśmy dostawę Twojej przesyłki i już tylko chwila dzieli Cię od jej
                    otrzymania. Jednak zanim to nastąpi prosimy zapoznaj się z <b>WAŻNYMI</b>{" "}
                    informacjami zawartymi poniżej.
                  </div>
                  <div>
                    Nie jesteśmy sklepem w którym zakupiłaś/eś meble. Świadczymy tylko usługę
                    dostawy i nazywamy się PAKKO. Specjalizujemy się w dostawach delikatnych
                    przesyłek ponadgabarytowych i wyróżniamy się rzetelną informacją o dostawie oraz
                    wielką dbałością o Twoją przesyłkę aby dotarła do Ciebie bezpiecznie.
                  </div>
                  <div>
                    Prosimy zweryfikuj dane dotyczące adresu dostawy i nr telefonu, jeżeli są
                    nieprawidłowe niezwłocznie skontaktuj się z nami.
                  </div>
                  <div>
                    W przypadku braku potwierdzenia przesyłka i tak zostaje nadana na koszt
                    odbiorcy, a za każdy kolejny transport naliczana jest opłata z uwagi na
                    gabaryty, dlatego jeżeli nie możesz jej odebrać koniecznie nas o tym poinformuj.
                  </div>
                  <div>
                    W przypadku przełożenia dostawy wolne terminy zazwyczaj wahają się od{" "}
                    <b>15-35 dni</b> i wszystko uzależnione jest od rezerwacji na nadchodzących
                    trasach.
                  </div>
                  <div>
                    <b>PRZYPOMINAMY że kierowca nie wnosi paczek, wydaje je tylko z pojazdu</b> z
                    uwagi na ich gabaryty i przepisy Prawa Pracy!{" "}
                  </div>
                  <div>
                    Do obowiązków kierowcy należy podjechanie najbliżej miejsca rozładunku jak tylko
                    to możliwe, zachowując przy tym wszelkie względy bezpieczeństwa i przepisy o
                    Ruchu Drogowym.{" "}
                  </div>
                  <div>
                    Podczas odbioru przesyłek prosimy o zachowanie należytej ostrożności, żeby nie
                    narażać zdrowia swojego oraz kierowcy.{" "}
                  </div>
                  <div>
                    W przypadku płatności gotówką prosimy o przygotowanie wyliczonej kwoty, kierowca
                    nie posiada terminalu płatniczego.{" "}
                  </div>
                  <div>
                    Z uwagi na charakter realizowanych dostaw z jednego magazynu centralnego na całą
                    Polskę zamówienia dostarczane są według kolejności klientów na danej trasie od
                    godziny 06:00 do 22:30. Kierowca jest w delegacji 2-3 dni i pokonuje dziennie
                    dystanse rzędu 500-700 km, a więc{" "}
                    <b>zmiana godziny i dnia dostawy na trasie jest niemożliwa. </b>{" "}
                  </div>
                  <div>
                    W dniu dostawy koniecznie <b>bądź dostępny pod telefonem kontaktowym</b> i
                    czekaj na informację od kierowcy, który skontaktuje się z Tobą na minimum 30
                    minut przed planowana dostawą
                  </div>
                  <div>
                    Są to ogólne warunki i niezapoznanie się z ich treścią oznacza automatyczną
                    akceptację. Pozdrawiamy zespół PAKKO.
                  </div>
                </div>
                <div className="text-color-grey mt-5">Nadawca</div>
                <h4 className={styles.bigLabel}>{result.order?.customer?.name}</h4>
                <div className="mt-4">
                  {result.order?.items.map(el => (
                    <div className={styles.item}>
                      {el.product.name} x{el.quantity}
                    </div>
                  ))}
                  <div className="text-color-grey mt-2">
                    Paczki: {result.order?.numberOfPackages} szt.
                  </div>
                  <div className="text-color-grey">
                    Łączna masa: {result.order?.totalWeight} kg.
                  </div>
                  <div className="mt-3">
                    <span className={styles.amount}>Kwota pobrania: </span>{" "}
                    <span className={styles.bigLabel}>
                      {pakkoTotalAmount()?.toFixed(2)} {result.order?.payment?.currency}
                    </span>
                    <div className="text-color-grey mt-1">Prosimy, przygotuj odliczoną kwotę.</div>
                  </div>
                </div>
              </div>
            )}
            <h1 className={cx(styles.headingPrimary)}>{pageContentPerStatus[status]["title"]}</h1>
            <p className={cx(styles.paragraph, "mt-2")}>
              {pageContentPerStatus[status]["subTitle"]}
            </p>
            {pageContentPerStatus[status]["main"]}
          </div>
          <div className="mt-5">
            {owner !== "pakko" && (
              <>
                <h4 className={styles.headingSecondary}>
                  {t("Potrzebujesz zmienić datę odbioru przesyłki?")}
                </h4>
                <p className={styles.paragraph}>
                  {t("Skontaktuj się z naszym działem handlowym")}:
                </p>
                <div className="d-flex align-items-center justify-content-between flex-column mt-2">
                  {result.phone && <div>{result.phone} </div>}
                  {result.email && (
                    <a className={styles.link} href={`mailto:${result.email}`}>
                      {result.email}
                    </a>
                  )}
                </div>
                {ownerConfig.showOfficeHours && (
                  <div className="mt-2">
                    <p className={styles.paragraph}>{t("Godziny otwarcia biura:")}</p>
                    <h4 className={styles.headingSecondary}>{result.officeOpeningHours}</h4>
                  </div>
                )}
              </>
            )}
            {owner === "pakko" && status !== 403 && (
              <div>
                <div className={cx("text-center mx-3", styles.desc)}>
                  {result.order?.delivery.firstName}, prosimy zweryfikuj wszystkie dane dotyczące
                  przesyłki. W przypadku jakichkolwiek nieprawidłowości, jesteśmy do Twojej
                  dyspozycji.
                </div>
                <div className={cx("text-center mx-3 mt-4", styles.desc)}>
                  Napisz do nas wiadomość, podając <strong>numer przesyłki </strong>oraz poprawne
                  dane, wysyłając e-mail lub SMS
                </div>
                <div className="d-flex align-items-center justify-content-between flex-column mt-4">
                  <div className="text-color-grey">Adres e-mail:</div>
                  {result.email && (
                    <a className={styles.link} href={`mailto:${result.email}`}>
                      {result.email}
                    </a>
                  )}
                  <div className="text-color-grey mt-3">Nr telefonu:</div>
                  {result.phone && (
                    <a className={styles.link} href={`tel:${result.phone}`}>
                      {result.phone}
                    </a>
                  )}
                  <div className={cx("text-center mx-3 mt-2 fs-20")}>
                    <b>PAKKO</b>
                  </div>
                  <div className={cx(styles.desc, "text-center mx-3 ")}>
                    Jeśli potrzebujesz więcej szczegółów, skontaktuj się z naszym działem
                    logistycznym od poniedziałku do piątku w godzinach 09:00 – 16:00 pod numerem
                    telefonu
                    <a className={cx(styles.link, "mx-1")} href={`tel:605586726`}>
                      605-586-762
                    </a>
                    lub adresem e-mail:
                    <a className={cx(styles.link, "mx-1")} href={`mailto:kontakt@pakko.pl`}>
                      kontakt@pakko.pl
                    </a>
                    Korespondując podaj swoje dane lub numer przesyłki, abyśmy wiedzieli z jakim
                    zamówieniem to powiązać.
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center logo pt-3 pb-5">
            <img src={logoIcon} alt="logo milo" />
          </div>
        </div>
      </div>
    </div>
  );
};
