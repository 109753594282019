import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery } from "hooks";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { DeleteTradingDocumentBtn } from "pages/tradingDocuments/shared/components/deleteTradingDocumentBtn/DeleteTradingDocumentBtn";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { DownloadRevisor } from "pages/tradingDocuments/shared/components/DownloadRevisor";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiHistory } from "components/miloDesignSystem/atoms/icons/MdiHistory";
import { useNavigate } from "hooks/useNavigate";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { DownloadTradingDocumentXml } from "pages/tradingDocuments/shared/components/downloadTradingDocumentXml/DownloadTradingDocumentXml";

interface Props {
  close: () => void;
}

export const PanelHeader = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: correctionInvoice } = useTradingDocument({ id: panelId });
  const { isTriggerElementHidden } = useScrollableContext();
  const navigate = useNavigate();

  if (!correctionInvoice) return null;

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper className="gap-2">
          <ConfirmCheckbox tradingDocument={correctionInvoice} />
          <div>Faktura VAT&nbsp;{correctionInvoice.signature}</div>
        </SlidingHeaderWrapper>
      ) : (
        <ConfirmTradingDocument tradingDocument={correctionInvoice} />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        <DownloadTradingDocumentXml tradingDocument={correctionInvoice} />
        <FileDownloadHandler
          factoryFn={() => tradingDocumentFileFactory.correctionPdf(correctionInvoice)}
          type="pdf"
        >
          {({ download, isLoading }) => (
            <Tooltip title="Pobierz PDF korekty">
              <IconButton
                icon={<MdiDownloadPdf size="18" />}
                isLoading={isLoading}
                onClick={download}
                variant="transparent"
              />
            </Tooltip>
          )}
        </FileDownloadHandler>
        <DownloadRevisor tradingDocumentIds={[correctionInvoice.id]} />
        <span className="divider line-divider" />
        <Tooltip title="Wyświetl historię korekty">
          <IconButton
            icon={MdiHistory}
            onClick={() =>
              navigate(`/finances/corrections/${panelId}/history`, { correctionInvoice })
            }
            variant="transparent"
          />
        </Tooltip>
        <span className="divider line-divider" />
        <DeleteTradingDocumentBtn
          close={close}
          tradingDocumentId={correctionInvoice.id}
          tradingDocumentSignature={correctionInvoice.signature}
          tradingDocumentStatus={correctionInvoice.status}
        />
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
