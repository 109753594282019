import { UUID } from "api/types";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./FinancialReportModal.module.css";
import { Formik } from "formik";
import { useSelector } from "hooks";
import { cx, dateUtils } from "utilities";
import { DatePicker } from "components/utils/datePicker";
import { Select } from "components/miloDesignSystem/molecules/select";
import { Button } from "components/miloDesignSystem/atoms/button";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";

interface Props {
  close: () => void;
}

export interface FilterValues {
  excludeAdvancements: boolean;
  excludeCorrections: boolean;
  invoiceDeliveryDateFrom: string;
  invoiceDeliveryDateTo: string;
  invoiceIssueDateFrom: string;
  invoiceIssueDateTo: string;
  seller: UUID;
}

export const FinancialReportModal = ({ close }: Props) => {
  const businessEntities = useSelector(store => store.partials.businessEntities);
  const internalBusinessEntitiesOptions = businessEntities
    .filter(entity => entity.kind === "INTERNAL")
    .map(entity => ({
      value: entity.id,
      text: entity.companyName,
      type: MenuItemType.TEXT,
    }));

  const initialValues: FilterValues = {
    excludeAdvancements: false,
    excludeCorrections: false,
    invoiceDeliveryDateFrom: "",
    invoiceDeliveryDateTo: "",
    invoiceIssueDateFrom: "",
    invoiceIssueDateTo: "",
    seller: internalBusinessEntitiesOptions[0]?.value,
  };

  const handleSubmit = tradingDocumentsActions.useDownloadSalesInvoicesFinancialReport(close);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Zestawienie dokumentów handlowych
        </Typography>
      }
      width={400}
    >
      <div className="p-3">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="pb-1">
                <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                  Data sprzedaży
                </Typography>
                <div className="d-flex gap-1">
                  <div className="position-relative mr-1">
                    <span className={styles.label}>Od:</span>
                    <DatePicker
                      placeholder="Wybierz"
                      value={values["invoiceDeliveryDateFrom"]}
                      selectsStart
                      removeDate={false}
                      tabIndex={-1}
                      look="common"
                      startDate={
                        values["invoiceDeliveryDateFrom"]
                          ? new Date(values["invoiceDeliveryDateFrom"])
                          : null
                      }
                      endDate={
                        values["invoiceDeliveryDateFrom"]
                          ? new Date(values["invoiceDeliveryDateFrom"])
                          : null
                      }
                      maxDate={
                        values["invoiceDeliveryDateTo"]
                          ? new Date(values["invoiceDeliveryDateTo"])
                          : null
                      }
                      onChange={date => {
                        if (!date) return;
                        setFieldValue("invoiceDeliveryDateFrom", dateUtils.formatDateToIso(date));
                      }}
                    />
                  </div>
                  <div className="position-relative">
                    <span className={styles.label}>Do:</span>
                    <DatePicker
                      placeholder="Wybierz"
                      tabIndex={-1}
                      removeDate={false}
                      value={values["invoiceDeliveryDateTo"]}
                      selectsEnd
                      look="common"
                      startDate={
                        values["invoiceDeliveryDateFrom"]
                          ? new Date(values["invoiceDeliveryDateFrom"])
                          : null
                      }
                      endDate={
                        values["invoiceDeliveryDateTo"]
                          ? new Date(values["invoiceDeliveryDateTo"])
                          : null
                      }
                      minDate={
                        values["invoiceDeliveryDateFrom"]
                          ? new Date(values["invoiceDeliveryDateFrom"])
                          : null
                      }
                      onChange={date => {
                        if (!date) return;
                        setFieldValue("invoiceDeliveryDateTo", dateUtils.formatDateToIso(date));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="pb-4">
                <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                  Data wystawienia
                </Typography>
                <div className="d-flex gap-1">
                  <div className="position-relative mr-1">
                    <span className={styles.label}>Od:</span>
                    <DatePicker
                      placeholder="Wybierz"
                      value={values["invoiceIssueDateFrom"]}
                      selectsStart
                      removeDate={false}
                      tabIndex={-1}
                      look="common"
                      startDate={
                        values["invoiceIssueDateFrom"]
                          ? new Date(values["invoiceIssueDateFrom"])
                          : null
                      }
                      endDate={
                        values["invoiceIssueDateFrom"]
                          ? new Date(values["invoiceIssueDateFrom"])
                          : null
                      }
                      maxDate={
                        values["invoiceIssueDateTo"] ? new Date(values["invoiceIssueDateTo"]) : null
                      }
                      onChange={date => {
                        if (!date) return;
                        setFieldValue("invoiceIssueDateFrom", dateUtils.formatDateToIso(date));
                      }}
                    />
                  </div>
                  <div className="position-relative">
                    <span className={styles.label}>Do:</span>
                    <DatePicker
                      placeholder="Wybierz"
                      tabIndex={-1}
                      removeDate={false}
                      value={values["invoiceIssueDateTo"]}
                      selectsEnd
                      look="common"
                      startDate={
                        values["invoiceIssueDateFrom"]
                          ? new Date(values["invoiceIssueDateFrom"])
                          : null
                      }
                      endDate={
                        values["invoiceIssueDateTo"] ? new Date(values["invoiceIssueDateTo"]) : null
                      }
                      minDate={
                        values["invoiceIssueDateFrom"]
                          ? new Date(values["invoiceIssueDateFrom"])
                          : null
                      }
                      onChange={date => {
                        if (!date) return;
                        setFieldValue("invoiceIssueDateTo", dateUtils.formatDateToIso(date));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="pb-2">
                <Checkbox
                  checked={!values["excludeCorrections"]}
                  label="Uwzględnij korekty"
                  onChange={status => setFieldValue("excludeCorrections", !status)}
                  size="sm"
                />
              </div>
              <div className="pb-2">
                <Checkbox
                  checked={!values["excludeAdvancements"]}
                  label="Uwzględnij faktury zaliczkowe"
                  onChange={status => setFieldValue("excludeAdvancements", !status)}
                  size="sm"
                />
              </div>
              <div className="pb-3">
                <Select
                  items={internalBusinessEntitiesOptions}
                  onChange={id => id && setFieldValue("seller", String(id))}
                  label="Wybierz podmiot biznesowy"
                  selected={values["seller"]}
                />
              </div>
              <div className="d-flex align-items-center gap-3 pt-3 borderTop">
                <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                  Anuluj
                </Button>
                <Button
                  className="text-uppercase"
                  disabled={isSubmitting}
                  size="medium"
                  type="submit"
                  variant="deepPurple"
                >
                  Pobierz
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
