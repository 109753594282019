import { customerActions } from "api/customers/actions";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { useParams } from "react-router";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "./OrdersSection.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { pluralize } from "utilities";

export const OtherOrdersSettings = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  assertIsDefined(customer);

  return (
    <div className="d-flex flex-column gap-4 p-1">
      <div className="d-flex align-items-baseline gap-1">
        <TextField.Async
          containerClassName={styles.discountInput}
          label="Stały rabat"
          mutationHook={customerActions.usePatchCustomer}
          size="small"
          transformQueryData={discount => ({
            id: customer.id,
            toUpdate: {
              discount,
            },
          })}
          type="number"
          value={customer.discount}
        />
        <Typography color="neutralBlack64" fontSize="12" fontWeight="600">
          %
        </Typography>
      </div>
      <div className="d-flex align-items-baseline gap-1">
        <TextField.Async
          containerClassName={styles.daysInput}
          label="Min. czas odbioru osobistego"
          mutationHook={customerActions.usePatchCustomer}
          size="small"
          transformQueryData={defaultCollectionDateDays => ({
            id: customer.id,
            toUpdate: {
              defaultCollectionDateDays: Number(defaultCollectionDateDays),
            },
          })}
          type="number"
          value={customer.defaultCollectionDateDays}
        />
        <Typography color="neutralBlack64" fontSize="12" fontWeight="600">
          {pluralize.pl(customer.defaultCollectionDateDays, {
            singular: "dzień",
            plural: "dni",
            other: "dni",
          })}
        </Typography>
      </div>
      <div className="d-flex align-items-baseline gap-1">
        <TextField.Async
          containerClassName={styles.daysInput}
          label="Czas dostawy do magazynu"
          mutationHook={customerActions.usePatchCustomer}
          size="small"
          transformQueryData={maximumDeliveryToWarehouseTime => ({
            id: customer.id,
            toUpdate: {
              maximumDeliveryToWarehouseTime: Number(maximumDeliveryToWarehouseTime),
            },
          })}
          type="number"
          value={customer.maximumDeliveryToWarehouseTime}
        />
        <Typography color="neutralBlack64" fontSize="12" fontWeight="600">
          {pluralize.pl(customer.defaultCollectionDateDays, {
            singular: "dzień",
            plural: "dni",
            other: "dni",
          })}
        </Typography>
      </div>
    </div>
  );
};
