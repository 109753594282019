import { ApiFetcher } from "hooks/createApiQuery";
import { queryFetch } from "apiConnectors/queryFetch";
import { Address, Customer, CustomerEmployee, GusDetails, PostCustomerEmployee } from "./models";
import { Pagination } from "api/types";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";
import { customerKeys } from "./keys";

const getCustomers = (search: string = ""): ApiFetcher<Pagination<Customer>> => ({
  key: customerKeys.customers(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/customers/items" + search,
    }),
});

const getCustomer = (customerId: number): ApiFetcher<Customer> => ({
  key: customerKeys.customerDetails(customerId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/customers/items/${customerId}`,
    }),
});

const getCustomerGusDetails = (taxId: string): ApiFetcher<GusDetails> => ({
  key: customerKeys.customerGusDetails(taxId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/customers/gus-customer-details/" + taxId,
    }),
});

const patchCustomer = ({ id, toUpdate }: { toUpdate: PartialOf<Customer>; id: Customer["id"] }) =>
  queryFetch<Customer>({
    method: "PATCH",
    url: "/customers/items/" + id,
    data: parsePatchData(toUpdate),
  });

const getCustomerEmployees = (search: string = ""): ApiFetcher<Pagination<CustomerEmployee>> => ({
  key: customerKeys.customerEmployees(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/customers/employees" + search,
    }),
});

const postCustomerEmployee = (data: PostCustomerEmployee) =>
  queryFetch<CustomerEmployee>({
    method: "POST",
    url: "/customers/employees",
    data,
  });

const deleteCustomerEmployee = (employeeId: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/customers/employees/" + employeeId,
  });

const deleteCustomerAddress = (addressId: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/customers/delivery-addresses/" + addressId,
  });

const patchCustomerAddress = ({
  id,
  toUpdate,
}: {
  toUpdate: PartialOf<Address>;
  id: number;
  customerId: number;
}) =>
  queryFetch<Address>({
    method: "PATCH",
    url: "/customers/delivery-addresses/" + id,
    data: parsePatchData(toUpdate),
  });

export const customerApi = {
  deleteCustomerAddress,
  deleteCustomerEmployee,
  getCustomerEmployees,
  getCustomerGusDetails,
  getCustomers,
  getCustomer,
  patchCustomer,
  patchCustomerAddress,
  postCustomerEmployee,
};
